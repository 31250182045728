import React from 'react'

// Components
import Header from './Header'
import Footer from './Footer'

// CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import '../assets/css/styles.min.css'

const Layout404 = ({children, currentPage, customPage}) => {

  return(
    <>
      {/* Header */}
      <Header customPage={(currentPage)? currentPage : null} />
      {(currentPage)?  <main id={currentPage}>{children}</main>
      : <main>{children}</main>
      }
      {/* Footer */}
      <Footer />
    </>
  )
}

export default Layout404